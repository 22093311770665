// Regular expression
// eslint-disable-next-line no-useless-escape
var regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/;
var regPhoneNumber = /^(((44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))$/g;
var digitalInput = /^[0-9]{0,}$/;
var regFullName = /^[a-zA-Z]{2,}(?:[a-zA-Z\s'-]{0,})[a-zA-Z]*$/;
var regPostcode = /^([A-Za-z]{1,2}\d[A-Za-z\d]? ?\d[A-Za-z]{2}|GIR ?0A{2})$/;
export var isEmailValid = function isEmailValid() {
  var email = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return regEmail.test(email.toLocaleLowerCase());
};
export var isPhoneValid = function isPhoneValid() {
  var phoneNumber = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return !!phoneNumber.match(regPhoneNumber);
};
export var isDigitInputValueValid = function isDigitInputValueValid(number) {
  return digitalInput.test(number);
};
export var isFullNameValid = function isFullNameValid() {
  var fullName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return regFullName.test(fullName);
};
export var isUKPostcodeValid = function isUKPostcodeValid() {
  var postcode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return regPostcode.test(postcode);
};