import { getNavigationConfig } from './getNavigationConfig';
export var getFooterConfig = function getFooterConfig(config) {
  if (!config) {
    return {};
  }

  var navigation = getNavigationConfig(config);
  var footerNavigation = navigation && navigation.footer;
  var newsletterConfig = config.newsletterConfig;
  return {
    logo: {
      src: config.branding.logo.src,
      height: config.branding.logo.height,
      width: config.branding.logo.width,
      url: config.branding.logo.url,
      alt: config.branding.logo.alt
    },
    secondaryLogo: {
      src: config.branding.logo.src,
      height: config.branding.logo.height,
      width: config.branding.logo.width,
      url: config.branding.logo.url
    },
    navigation: footerNavigation,
    newsletterConfig: newsletterConfig
  };
};