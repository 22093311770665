function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Extracts content for the ArticleGroup component.
 * @param {Array} related Array of objects that have a type and content key.
 * @param {String} key    What key to look for. Defaults to most popular.
 * @param {String} titleKey    What title key to look for. Defaults to most popular.
 */
var getSiteBlockGroupContent = function getSiteBlockGroupContent(_ref) {
  var related = _ref.related,
      _ref$key = _ref.key,
      key = _ref$key === void 0 ? 'siteBlock' : _ref$key,
      _ref$titleKey = _ref.titleKey,
      titleKey = _ref$titleKey === void 0 ? 'Tips & advice' : _ref$titleKey;
  var blocks = Array.isArray(related) ? related.filter(function (_ref2) {
    var type = _ref2.type;
    return type === key;
  }) : [];
  var block = blocks.find(function (_ref3) {
    var title = _ref3.title;
    return title === titleKey;
  });
  var relatedContent = (block ? block.content : []).map(function (item) {
    return _objectSpread(_objectSpread({}, item), {}, {
      title: item.altTitle || item.title
    });
  });
  return {
    block: block,
    relatedContent: relatedContent
  };
};

export default getSiteBlockGroupContent;