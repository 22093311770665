function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import get from 'lodash.get';
/**
 * Function to convert menus from format in middleware to component library version.
 *
 * Menus in middleware are an array of menus with a "type" (e.g. footer, header), and either reference an
 * external URL or a page entity instead of explicitly declaring the target attribute.
 * @param {Object}   The configuration object from the middleware.
 * @returns {Object} Converted navigation object.
 */

export var getNavigationConfig = function getNavigationConfig(config) {
  if (!config) {
    return {};
  }

  var getMenu = get(config, 'globalSettings.getMenu');

  if (!getMenu) {
    return {};
  }

  var navigation = {};
  getMenu.forEach(function (_ref) {
    var type = _ref.type,
        items = _ref.items;
    navigation[type] = items.map(function (menuItem) {
      var entity = menuItem.item,
          item = _objectWithoutProperties(menuItem, ["item"]);

      if (entity && entity.url) {
        return _objectSpread(_objectSpread({}, item), {}, {
          url: entity.url
        });
      }

      var rel = type === 'header' ? 'noopener noreferrer nofollow' : null;
      return _objectSpread(_objectSpread({}, item), {}, {
        target: '_blank',
        rel: rel
      });
    });
  });
  return navigation;
};