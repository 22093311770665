function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import PropTypes from 'prop-types';
export var adSettingsShape = {
  adSettings: PropTypes.shape({
    afterNavigationMobileAds: PropTypes.bool,
    afterNavigationDesktopAds: PropTypes.bool,
    numberOfWordsForFirstInjectionDesktop: PropTypes.number,
    numberOfWordsForFirstInjectionMobile: PropTypes.number,
    numberOfWordsBetweenInlineDesktopAds: PropTypes.number,
    numberOfWordsBetweenInlineMobileAds: PropTypes.number,
    minNumOfWordsAfterLastInlineAdDesktop: PropTypes.number,
    minNumOfWordsAfterLastInlineAdMobile: PropTypes.number,
    listGalleryAds: PropTypes.number,
    refreshTime: PropTypes.number,
    refreshBlacklist: PropTypes.string,
    refreshSiteWide: PropTypes.bool,
    minNumOfWordsBeforeTeads: PropTypes.number,
    galleryBlockDesktopAds: PropTypes.number,
    galleryBlockMobileAds: PropTypes.number,
    galleryListDesktopAds: PropTypes.number,
    galleryListMobileAds: PropTypes.number,
    galleryItemDescriptionAds: PropTypes.number
  })
};
export var breakpointsShape = {
  breakpoints: PropTypes.shape({
    desktop: PropTypes.arrayOf(PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number
    })),
    mobile: PropTypes.arrayOf(PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number
    }))
  })
};
export var configPartialShape = {
  header: PropTypes.shape({
    logoImage: PropTypes.string
  }),
  siteUrl: PropTypes.string.isRequired,
  social: PropTypes.shape({
    share: PropTypes.shape({
      platforms: PropTypes.array
    })
  }),
  newsletter: PropTypes.object
};
export var dataLayerShape = {
  dataLayer: PropTypes.shape({
    product: PropTypes.string,
    productFamily: PropTypes.string,
    make: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
    nodeId: PropTypes.string
  }).isRequired
};
export var globalSettingsShape = {
  globalSettings: PropTypes.shape(_objectSpread({
    newsletterLink: PropTypes.string,
    promoBoxSettings: PropTypes.shape()
  }, adSettingsShape)).isRequired
};
export var metaDataShape = {
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
    ogTitle: PropTypes.string,
    ogDescription: PropTypes.string,
    twitterHandle: PropTypes.string,
    twitterTitle: PropTypes.string,
    twitterDescription: PropTypes.string
  })
};
export var layoutDataShape = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape(_objectSpread({
      associatedContent: PropTypes.arrayOf(PropTypes.object),
      body: PropTypes.arrayOf(PropTypes.object),
      breadcrumbs: PropTypes.arrayOf(PropTypes.object),
      isSponsored: PropTypes.bool,
      kicker: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string
      })]),
      primaryMedia: PropTypes.object,
      sponsor: PropTypes.object,
      subtitle: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.object),
      title: PropTypes.string,
      url: PropTypes.string,
      layoutType: PropTypes.string,
      hideTitle: PropTypes.bool,
      fullWidth: PropTypes.bool,
      authors: PropTypes.arrayOf(PropTypes.object),
      monetising: PropTypes.arrayOf(PropTypes.shape({
        config: PropTypes.arrayOf(PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })).isRequired,
        enabled: PropTypes.bool.isRequired,
        service: PropTypes.string.isRequired
      }).isRequired),
      heroImage: PropTypes.shape({
        heroImageDesktop: PropTypes.object,
        heroImageMobile: PropTypes.object
      })
    }, metaDataShape)).isRequired
  }).isRequired
};
export var paginationShape = {
  pagination: PropTypes.shape({
    isLoading: PropTypes.bool,
    loadMore: PropTypes.func,
    page: PropTypes.number,
    pages: PropTypes.object,
    setPageRef: PropTypes.func,
    hasMore: PropTypes.bool,
    nextUrl: PropTypes.string
  }).isRequired
};