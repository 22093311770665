/**
 * Get review page URL from spec page
 *
 * @param {*} url
 * @param {*} specTemplate
 *
 *  @todo Retrieve URL from middleware when possible and not rely on hardcoded value for pricesSpecsSlug.
 */
export var getReviewURLFromSpecPage = function getReviewURLFromSpecPage(url, specTemplate) {
  var pricesSpecsSlug = '/prices-specs';
  var reviewURLFromSpecPage = url.substring(0, url.indexOf(pricesSpecsSlug));

  if (specTemplate === 'specs') {
    reviewURLFromSpecPage += pricesSpecsSlug;
  }

  return reviewURLFromSpecPage;
};