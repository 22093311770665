function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import PropTypes from 'prop-types';
import { breakpointsShape, configPartialShape, dataLayerShape, globalSettingsShape, layoutDataShape } from 'Helpers/props-shapes';
var configShape = {
  config: PropTypes.shape(_objectSpread(_objectSpread(_objectSpread({}, configPartialShape), globalSettingsShape), breakpointsShape)).isRequired
};
export var specPropsShape = _objectSpread(_objectSpread(_objectSpread({}, configShape), dataLayerShape), layoutDataShape);
export var vehicleSpecsPropsShape = {
  vehicleSpecs: PropTypes.shape({
    name: PropTypes.string.isRequired,
    summary: PropTypes.shape({
      doors: PropTypes.number,
      driveTrain: PropTypes.string,
      fuelDelivery: PropTypes.string,
      transmission: PropTypes.string,
      fuelType: PropTypes.string,
      CC: PropTypes.number,
      trim: PropTypes.string,
      price: PropTypes.number
    }).isRequired,
    technicalData: PropTypes.arrayOf(PropTypes.object),
    standardEquipment: PropTypes.arrayOf(PropTypes.object)
  })
};