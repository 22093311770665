/**
 * Get review page title from spec page
 *
 * @param {*} title
 * @param {*} specTemplate
 */
export var getReviewTitleFromSpecPage = function getReviewTitleFromSpecPage(title, specTemplate) {
  var reviewTitleFromSpecPage;

  if (specTemplate === 'specs') {
    reviewTitleFromSpecPage = 'All Models';
  } else {
    reviewTitleFromSpecPage = title.substring(0, title.indexOf(' : Prices & Specs'));

    if (!reviewTitleFromSpecPage) {
      reviewTitleFromSpecPage = title;
    } // add "review" at end of title


    var titleSuffix = ' review';

    if (!reviewTitleFromSpecPage.endsWith(titleSuffix)) {
      reviewTitleFromSpecPage += titleSuffix;
    }
  }

  return reviewTitleFromSpecPage;
};