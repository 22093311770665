function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Extracts authors for PostMeta component.
 * @param {Array} authors Array of authors from middleware.
 * @returns {Object}
 */
export function getAuthors(authors) {
  if (authors === false) {
    return false;
  }

  var author = {
    author: 'Carbuyer'
  };
  var authorsList = [];
  var maxAuthors = 3;

  if (authors && Array.isArray(authors)) {
    if (authors.length === 1) {
      author.author = authors[0].name;
      author.authorUrl = authors[0].url;
      author.authorImage = {
        src: authors[0].image ? authors[0].image.src : ''
      };
    } else if (authors.length <= maxAuthors) {
      authors.forEach(function (_ref, index) {
        var name = _ref.name,
            url = _ref.url,
            image = _ref.image;

        if (index < maxAuthors) {
          authorsList.push({
            author: name,
            authorUrl: url,
            authorImage: {
              src: image ? image.src : ''
            }
          });
        }
      });
    } else {
      authorsList.push({
        author: authors[0].name,
        authorUrl: authors[0].url,
        authorImage: {
          src: authors[0].image ? authors[0].image.src : ''
        }
      }, {
        author: authors[1].name,
        authorUrl: authors[1].url,
        authorImage: {
          src: authors[1].image ? authors[1].image.src : ''
        }
      }, _objectSpread({}, author));
    }
  }

  return authorsList.length > 1 ? {
    authors: authorsList
  } : _objectSpread({}, author);
}