import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import getSiteBlockGroupContent from './getSiteBlockGroupContent';
/**
* Return Most popular article group.
* @param {String} datePosition Position for date meta/bottom.
* @param {String} title Group title.
* @param {Array} associatedContent Array of objects that have a type and content key.
*/

export var getSiteBlockGroup = function getSiteBlockGroup(_ref) {
  var _ref$articleCardProps = _ref.articleCardProps,
      articleCardProps = _ref$articleCardProps === void 0 ? {
    datePosition: 'meta',
    excerpt: null,
    isHeading: false
  } : _ref$articleCardProps,
      _ref$articlesPerRow = _ref.articlesPerRow,
      articlesPerRow = _ref$articlesPerRow === void 0 ? 4 : _ref$articlesPerRow,
      associatedContent = _ref.associatedContent,
      extraClassNames = _ref.extraClassNames,
      title = _ref.title;
  var groupContentOptions = {
    related: associatedContent,
    key: 'siteBlock',
    titleKey: title
  };

  var _getSiteBlockGroupCon = getSiteBlockGroupContent(groupContentOptions),
      block = _getSiteBlockGroupCon.block,
      relatedContent = _getSiteBlockGroupCon.relatedContent;

  return getArticleGroup({
    articleCardProps: articleCardProps,
    articlesPerRow: articlesPerRow,
    ctaUrl: block && block.url,
    ctaLabel: block && block.url && block.subtitle,
    extraClassNames: extraClassNames,
    content: relatedContent,
    title: title
  });
};