function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

export var convertFormData = function convertFormData(formData, extraData) {
  return _objectSpread({
    data: {
      title: 'Owner Review',
      average_annual_mileage: formData.mileage,
      electric_range: formData.electricRange,
      related_article: formData.relatedArticle,
      ownership_years: formData.ownershipYears,
      typical_mpg: formData.mpg,
      recommended: formData.recommended,
      review: formData.review,
      improve: formData.improve,
      recommend: formData.recommend,
      review_rating: formData.rating,
      vrm: formData.vrm,
      name: formData.name,
      email: formData.email,
      postcode: formData.postcode
    }
  }, extraData);
};
export var date = Date.now();
export var DATALAKE_SERVICE = 'ownerReviews';